import { TransformType } from '@mr-yum/cdn-image'
import { ImageLoaderProps } from 'next/image'

import { config } from '@/lib/config'
import { cloudflareImageUrl } from '@/utils/cloudflareImageUrl'

export const cloudflareLoader = ({
  loaderOptions,
  transform = TransformType.DEFAULT,
  hasError = false,
}: {
  loaderOptions: ImageLoaderProps
  transform?: TransformType
  hasError?: boolean
}) => {
  const { src } = loaderOptions

  if (hasError) {
    return src
  }

  return cloudflareImageUrl(config.cloudflareImageResizingUrl, {
    ...loaderOptions,
    transform,
  })
}
