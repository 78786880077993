import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import {
  Alert,
  Button,
  ErrorNotification,
  InfoFilledIcon,
} from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { routes } from '@/lib/routes'

interface NotFoundProps {
  title?: ReactNode
  message?: ReactNode
  error?: Error | null
  clearError?: () => void
  className?: string
  showRecovery?: boolean
}

export const NotFound = ({
  title,
  message,
  error,
  clearError,
  className,
  showRecovery = true,
}: NotFoundProps) => {
  const intl = useIntl()
  const { query } = useRouter()
  const backRoute = query.venueSlug ? routes.venue : routes.home
  const venueSlug = (query.venueSlug as string) ?? ''

  return (
    <div
      className={cn(
        'flex h-screen w-screen flex-col items-center justify-center gap-8 bg-surface-ui-background p-4 text-center text-foreground',
        className,
      )}
    >
      <div>
        <img
          src="/static/toast.png"
          alt={intl.formatMessage({
            id: '37n4mf',
            defaultMessage: 'burnt toast',
          })}
          width={100}
        />
      </div>

      <h3 className="my-heading-sm">
        {title || (
          <FormattedMessage
            defaultMessage="Bummer, we got nothin’. Please check the URL for typos."
            id="rqYGU5"
            description="When user tries to visit a page that does not exist, informal"
          />
        )}
      </h3>

      {showRecovery && (
        <div
          className="flex w-full max-w-[500px]"
          data-testid="recovery-container"
        >
          <div className="mr-2 flex-1">
            <RouteLink route={backRoute} pathData={{ venueSlug }}>
              <Button as="a" fullWidth>
                <FormattedMessage defaultMessage="Back to menu" id="AgoOEq" />
              </Button>
            </RouteLink>
          </div>

          {clearError && (
            <div className="mr-2 flex-1">
              <Button
                type="button"
                variant="outline"
                onClick={clearError}
                fullWidth
              >
                <FormattedMessage defaultMessage="Try again" id="FazwRl" />
              </Button>
            </div>
          )}
        </div>
      )}

      {message && (
        <div>
          <Alert icon={<InfoFilledIcon />} variant="info-subtle" fullWidth>
            {message}
          </Alert>
        </div>
      )}

      {error && (
        <div>
          <ErrorNotification fullWidth error={error} />
        </div>
      )}
    </div>
  )
}
